import Collection from "components/Collection";
import Layout from "components/Layout";
import SEO from "components/Seo";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";

function PhotosPage() {
  const data = useStaticQuery(graphql`
    query PhotosQuery {
      allContentfulPagePhotos {
        edges {
          node {
            pageTitle
            heroSectionHeading
            images {
              id
              fluid(maxWidth: 800, quality: 90) {
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
              }
              fixed(width: 240, quality: 80) {
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
              }
            }
          }
        }
      }
    }
  `);
  const dataNode = data.allContentfulPagePhotos.edges[0].node;
  return (
    <Layout>
      <SEO
        // metaDescription={dataNode.pageDescription}
        title={dataNode.pageTitle}
      />
      <Collection data={dataNode} />
    </Layout>
  );
}
export default PhotosPage;
